import React from "react"
import Footer from "../components/Footer/Footer"
import Navbar from "../components/Navbar/Navbar"

const DocumentationPage = () => {
  return (
    <div>
      <Navbar />
      <div className=" text-white w-[100%] h-auto mt-40">
        <DocumentationElement
          Title="Title 27"
          Content="PAR 25: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod. 
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam"
          IMGSrc="Img"
          ButtonText="View PDF"
        />
        <DocumentationElement
          Title="Title 27"
          Content="PAR 25: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod. 
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam"
          IMGSrc="Img"
          ButtonText="View PDF"
        />
        <DocumentationElement
          Title="Title 27"
          Content="PAR 25: Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod. 
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam"
          IMGSrc="Img"
          ButtonText="View PDF"
        />
      </div>
      <Footer />
    </div>
  )
}

const DocumentationElement = ({ Title, Content, IMGSrc, ButtonText }) => (
  <div className="w-full flex flex-col justify-center items-center">
    <div className="flex justify-center h-auto lg:my-2 lg:py-0 lg:mb-0 mb-2 max-w-6xl">
      <div className="flex lg:flex-row flex-col-reverse lg:justify-between justify-center items-center w-[90%]">
        <div className="lg:w-[100%] w-[100%] flex lg:flex-row lg:justify-start flex-col justify-center items-center aspect-[5/4]">
          <div
            className={`mt-2 lg:mt-0 w-[85%] lg:w-[70%] aspect-[5/4] flex flex-col justify-center`}
          >
            <div className="font-bold lg:text-3xl text-2xl lg:mb-6 lg:pt-0 pt-2">
              {Title}
            </div>
            <div className="mt-2 text-bold text-md lg:ml-0 lg:mb-6 leading-relaxed font-light">
              {Content}
            </div>
            <div className="border-2 border-current lg:py-2 lg:mt-2 mt-4 lg:mb-0 mb-20 lg:p-0 p-2 aspect-[6/1] lg:w-[40%] w-[70%] flex items-center justify-center lg:text-md text-lg cursor-pointer opacity-70">
              {ButtonText}
            </div>
          </div>
        </div>
        <div className="lg:w-[50%] lg:h-96 w-[85%] bg-blue-400 aspect-[5/4] rounded-tr-[10%] rounded-bl-[10%]">
          {IMGSrc}
        </div>
      </div>
    </div>
  </div>
)

export default DocumentationPage
